<template>
  <div :id="el"></div>
</template>

<script>
  import { Player, Events } from 'clappr';
  // import PlaybackSpeedPlugin from '../deps/playBackSpeed.js'
  import levelSelector from 'level-selector'

  export default {
    name: 'VClappr',
    props: {
      var_view: {
        type: Boolean,
        required: false,
        default: false
      },
      el: {
        type: String,
        required: true,
        default: 'player',
      },
      source: {
        type: String,
        required: true,
        default: '',
      },
      record_source: {
        type: String,
        required: false,
        default: '',
      },
      useDvrControls: {
        type: Boolean,
        required: true
      },
      subtitles: {
        type: String,
        required:  false,
        default: ''
      },
      showSubtitles: {
        type: Boolean,
        required:  false,
        default: false
      },
      options: {
        type: Object,
        required: false,
        default: () => 
          {return {
          width: '100%',
          height: '100%',
          poster: '',
          mute: false,
          autoplay: true,
          parentId: '#player',
          loop: false,
          disable_context_menu: false,
          disable_keyboard_shortcuts: false,
          mediacontrol: {},
          // ga: {
          //   account: '',
          //   tracker_name: '',
          // },
          watermark: {
            image: '',
            url: '',
            position: 'top-right',
          },
          playback: {
            playInline: true
          }
          }},
      },
      live: {
        type: Boolean,
        required: true
      },
      initialTime: {
        type: Number,
        required: true
      }
    },
    data: () => ({
      player: null,
      showOverlay: true,
      overlayText: 'Siirry tästä verkostoitumaan',
      showOverlayButton: true,
      overlayButtonText: 'Siirry verkostoitumaan',
      overlayData: [],
      timeOutInterval: undefined,
      overlayIndex: 0,
      visibleOverlayUuid: undefined,
      initTimestamp: 0,
    }),
    created() {
      // if(this.live) this.fetchOverlayData();
      // this.fetchOverlayData();
    },
    mounted() {
      // Initialze the Player by Clappr
      const player = this.initClapprPlayer();
      // Save `player` object to data
      this.player = player;
      // Register Clapper Player Events
      this.registerEvents(player);

      this.setCurrentTime(this.initialTime)
    },
    beforeDestroy() {
      this.player.destroy();
      this.player = null;
      clearInterval(this.timeOutInterval)
    },
    methods: {
      toggleCC(show) {
        // if(video.textTracks['0'] && this.subtitles) {
        //   video.textTracks['0'].mode = 'showing'
        //   if(this.showSubtitles == 'false') video.textTracks['0'].mode = 'disabled'
        //   // if(this.showSubtitles) video.textTracks['0'].mode = 'showing'
        //   // else video.textTracks['0'].mode = 'disabled'
        //   // video.textTracks['0'].mode = this.showSubtitles ? 'showing' : 'disabled'
        // }
        let video = document.querySelector(`video`)
        if(video.textTracks['0'] && video.textTracks['0'].mode) {
          video.textTracks['0'].mode = show ? 'showing' : 'disabled'
        }
      },
      setCurrentTime(time) {
        this.player.seek(time)
        this.player.play()
      },
      skip(time) {
        let new_time = Math.max(this.player.getCurrentTime() + time, 1)
        new_time = Math.min(this.player.getCurrentTime() + time, this.player.getDuration() - 3)
        this.player.seek(new_time)
      },
      setPlaybackRate(rate) {
        document.querySelector('video').playbackRate = rate;
      },
      fetchOverlayData() {
        clearInterval(this.timeOutInterval);

        this.refetchOverlayDataInterval = setTimeout(this.fetchOverlayData, 5000)
        this.timeOutInterval = setInterval(this.checkOverlays, 1000);

        // Tässä sitten fetchataan uudet daattat overlaysta
        setTimeout(() => {
          this.overlayData = [{stream_time: 2, duration: 2}, {stream_time: 5, duration: 3}]
        }, 1000)
      },
      checkOverlays() {
        this.removeElementsByClass('overlay')
        for(let i = 0; i < this.overlayData.length; i++) {
          let d = this.overlayData[i];
          let timeNow = this.player.getCurrentTime();
          if(    timeNow > d.stream_time
              && timeNow < d.stream_time + d.duration 
              && d.stream_time > 0
              && d. stream_time) this.newOverlay(true, 'Testi', 'Siirry')
        }
      },
      newOverlay(showButton, text, buttonText) {
      // newOverlay() {
        const overlay = document.createElement('div');
        overlay.className = 'overlay overlay--small overlay--top-left';
        overlay.innerHTML = `<div class="overlay__text">${text}</div>`;
        if(showButton) overlay.innerHTML += `<div class="overlay__button__container"><div class="overlay__button">${buttonText}</div></div>`;
        document.querySelector('#player .container').append(overlay);
      },
      removeElementsByClass(className){
          var elements = document.getElementsByClassName(className);
          while(elements.length > 0){
              elements[0].parentNode.removeChild(elements[0]);
          }
      },
      initClapprPlayer() {
        // New Player instance
        
        // TODO Selvitä selkeämpi default-pluginien käsittely
        //this.options.plugins = [levelSelector]

        const player = new Player({
          parentId: `#${this.el}`,
          source: this.record_source || this.source,
          useDvrControls: this.useDvrControls,
          width: this.options.width,
          height: this.options.height,
          autoPlay: this.options.autoplay,
          poster: this.options.poster,
          mute: this.options.mute,
          hideMediaControl: this.options.hideMediaControl,
          loop: this.options.loop,
          mediacontrol: this.options.mediacontrol,
          disableKeyboardShortcuts: this.options.disable_keyboard_shortcuts,
          disableVideoTagContextMenu: this.options.disable_context_menu,
          // gaAccount: this.options.ga.account,
          // gaTrackerName: this.options.ga.tracker_name,
          watermark: this.options.watermark.image,
          watermarkLink: this.options.watermark.url,
          position: this.options.watermark.position,
          //plugins: this.options.plugins,
          plugins: [levelSelector], // TODO Selvitä selkeämpi default-pluginien käsittely
          playback: this.options.playback,
          clapprStats: {
            // optional: time in miliseconds for each report.
            // default: 5000
            runEach: 5000,
            // optional: callback function.
            // default: console.log
            onReport: (metrics) => {
              this.$emit('metrics', metrics)
            },
            // Fire PERCENTAGE_EVENT when video complete some percentage.
            // default: []
            onCompletion: [10, 20, 50, 100],
            // optional: provide an img uri hosted at the same place as your farm
            // or near of it prefferable 1x1px, without caching.
            // default: none
            // uriToMeasureLatency: 'http://www.example.com/images/tv/pixel-1x1-red.gif',
            // optional: provide some assets uris hosted at the same place as your farm
            // or near of it prefferable in an uncompressible file format, without caching.
            // default: none
            // urisToMeasureBandwidth: [
            //   {url: 'http://www.example.com/images/tv/500kb.jpg', timeout: 3000},
            //   {url: 'http://www.example.com/images/tv/1mb.jpg', timeout: 6000},
            //   {url: 'http://www.example.com/images/tv/5mb.jpg', timeout: 12000}
            // ],
            // optional: number of reports between two consecutive bandwidth tests.
            // default: 10
            // runBandwidthTestEvery: 10
          }
        });
        // Emit init event passing map object
        this.$emit('init', player);
        return player;
      },
      registerEvents(player) {
        // Emit the Ready event
        player.on(Events.PLAYER_READY, () => {
          this.$emit('ready', player);
        });
        // Emit the Play event
        player.on(Events.PLAYER_PLAY, () => {
          this.$emit('play', player);
          this.toggleCC(this.showSubtitles)
        });
        // Emit the pause event
        player.on(Events.PLAYER_PAUSE, () => {
          this.$emit('pause', player);
        });
        // Emit the Stop event
        player.on(Events.PLAYER_STOP, () => {
          this.$emit('stop', player);
        });
        // Emit the End event
        player.on(Events.PLAYER_ENDED, () => {
          this.$emit('ended', player);
        });
        // Emit the Fulscreen event
        player.on(Events.PLAYER_FULLSCREEN, (bool) => {
          this.$emit('fullscreen', bool);
        });
        // Emit the Resize event
        player.on(Events.PLAYER_RESIZE, (resize) => {
          this.$emit('resize', resize);
        });
        // Emit the Seek event
        player.on(Events.PLAYER_SEEK, (time) => {
          this.$emit('seek', time);
        });
        // Emit the Time update event
        player.on(Events.PLAYER_TIMEUPDATE, (progress) => {
          this.$emit('timeupdate', progress);
        });
        // Emit the Volume update event
        player.on(Events.PLAYER_VOLUMEUPDATE, (volume) => {
          this.$emit('volumeupdate', volume);
        });

        // Emit the Play Error event
        player.on(Events.PLAYER_ERROR, (err) => {
          this.$emit('error', {'error_type': Events.PLAYER_ERROR, 'error_obj': err});
        });
        player.on(Events.PLAYBACK_ERROR, (err) => {
          this.$emit('error', {'error_type': Events.PLAYBACK_ERROR, 'error_obj': err});
        }); 
        // Emit the bitrate change event
        player.core.activeContainer.on(Events.CONTAINER_BITRATE, (bitrate) => {
          // https://clappr.github.io/classes/Events.html#event_CONTAINER_BITRATE
          this.$emit('bitrate', bitrate);
        });
      },
    }
  };
</script>

<style lang="scss">
  .overlay {
    // background-color: rgba(0,0,0,0);
    position: relative;
    display: grid !important;
    grid-template-rows: 60% 40% !important;
    // border-right: 5px solid white;
    border-right: 5px solid white !important;
    border-bottom: 5px solid white !important;
    color: white;
    text-align: center;
    // -webkit-box-shadow: -2px 4px 21px 0px rgba(0,0,0,0.75);
    // -moz-box-shadow: -2px 4px 21px 0px rgba(0,0,0,0.75);
    // box-shadow: -2px 4px 21px 0px rgba(0,0,0,0.75);

    &__text {
      margin-top: 5px;
      font-size: 15px !important;
      // text-shadow: 4px 1px 3px rgba(158, 158, 158, 1);
    }

    &__button__container {
      text-align: center !important;
    }

    &__button {
      display: inline-block !important;
      font-size: 15px !important;
      width: 30%;
      background-color: #0012b4;
      color: white;
      cursor: pointer !important;
      border-radius: 5px;
      transition-duration: 0.2s;

      &:hover {
        transform: scale(1.1, 1.1)
      }
    }

    &--small {
      height: 100px;
      width: 200px;
      z-index: 1001 !important;
    }

    &--top-left {
      top: 10px;
      left: 10px;
    }

    &--large {
      width: 90%;
      left: 5%;
      height: 90%;
      top: 5%;
      z-index: 1000 !important;

      &:hover {
        transform: scale(1.0, 1.0)
      }
    }

    &__line {
      width: 100%;
      height: 1px;
      background-color: lightgrey;
    }
  }

  .no-fade-media-control {
    .media-control .media-control-background {
      display: none;
    }
  }
</style>
