import Vue from 'vue'
import VueRouter from 'vue-router'

import EventView from '../views/EventView.vue'
import FullScreenChatView from '../views/FullScreenChatView.vue'
import EventsView from '../views/EventsView.vue'
import ExplorerView from '../views/ExplorerView.vue'
import WildcardView from '../views/WildcardView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/c/:id/e/:eventid',
        name: 'Event',
        component: EventView
    },
    {
        path: '/c/:id/e/chat/:eventid',
        name: 'EventChat',
        component: FullScreenChatView
    },
    {
        path: '/c/:id',
        name: 'Events',
        meta: { 
            hideBackButton: true,
            showFooter: true
        },
        component: EventsView
    },
    {
        path: '/explorer',
        name: 'Explorer',
        meta: { hideBackButton: true },
        component: ExplorerView
    },
    {
        path: '/*',
        name: 'Wildcard',
        meta: {
            hideBackButton: true
        },
        component: WildcardView
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

export default router
