<template>
    <div class="block">
        <div class="block__num">
            {{num}}
        </div>
        <div class="block__text">
            {{text}}
        </div>
    </div>
</template>

<script>
    export default {
        props: ['num', 'text']
    }
</script>

<style lang="scss">
    .block {
        height: 200px;
        width: 200px;
        background-color: #fefefe;
        display: inline-block;
        margin: 100px 1%;
        border-radius: 10px;
        padding-top: 48px;

        &__num {
            font-size: 70px;
        }
    }

    @media only screen and (max-width: 1270px) {
        .block {
            height: 150px;
            width: 150px;
            margin: 100px 1%;
            padding-top: 20px;

            &__num {
                font-size: 60px;
            }
        }
    }

    @media only screen and (max-width: 1003px) {
        .block {
            height: 100px;
            width: 100px;
            margin: 80px 1%;
            padding-top: 10px;

            &__num {
                font-size: 40px;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .block {
            height: 70px;
            width: 70px;
            margin: 40px 1%;
            padding-top: 12px;

            &__num {
                font-size: 20px;
            }
        }
    }

</style>