<template>
    <div class="countdown">
        <div class="countdown__header">{{languages[currentLang].alkaa}}: {{getStartDate(starttime)}} {{getStartTime(starttime)}}</div>
        <CountdownBlock text="Days" :num="days"></CountdownBlock>
        <CountdownBlock text="Hours" :num="hours"></CountdownBlock>
        <CountdownBlock text="Minutes" :num="minutes"></CountdownBlock>
        <CountdownBlock text="Seconds" :num="seconds"></CountdownBlock>
    </div>
</template>

<script>
    import CountdownBlock from './CountdownBlock.vue'
    import SrvMixin from '../mixins/srvTimeMixin'

    export default {
        data() {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                interval: '',
                interval2: '',
                secondsInADay: 60 * 60 * 24,
                secondsInAnHour: 60 * 60,
                testDiff: 3,
                serverOffset: 0
            }
        },
        props: ['starttime'],
        mixins: [SrvMixin],
        components: {
            CountdownBlock
        },
        created() {
            this.startFlipdown();
        },
        methods: {
            startFlipdown() {
                // Päivitä serverin ja tietokoneen kellon erotus joka viides minuutti
                this.interval2 = setInterval(this.getServerOffset, 60 * 5 * 1000);
                
                this.interval = setInterval(this.getCurrentDiff, 1000);
            },
            getCurrentDiff() {
                // Kutsutaan joka sekunti
                let time = parseInt(Date.now() / 1000) - this.serverOffset;
                let diff = this.starttime + 2 - time;
                
                //Laitetaan aika näkyville
                if(diff <= 0) this.stopFlipdown()
                
                this.days = Math.floor(diff / this.secondsInADay)
                diff -= this.days * this.secondsInADay;
                this.hours = Math.floor((diff) / this.secondsInAnHour)
                diff -= this.hours * this.secondsInAnHour;
                this.minutes = Math.floor((diff) / 60)
                diff -= this.minutes * 60;
                this.seconds = diff
            },
            getServerOffset() {
                // Laskee serverin ja clientin kellojen erotuksen sekuntteina, kutsutaan het alussa
                // ja joka viides minuutti
                this.srvTime()
                    .then((serverTime) => {
                        this.serverOffset = parseInt(( Date.now() - serverTime ) / 1000)
                    })
            },
            stopFlipdown() {
                clearInterval(this.interval);
                this.$emit('timesUp')
            },
            getStartDate(unix) {
                var a = new Date(unix * 1000);
                return a.getDate() + '.' + (1 + a.getMonth()) + '.' + a.getFullYear();
            },
            getStartTime(unix) {
                var a = new Date(unix * 1000);
                return a.toString().substring(15, 21)
            },
        },
        computed: {
            currentLang() {
                return this.$store.state.lang
            },
            languages() {
                return this.$store.state.eventLanguages
            }
        },
        beforeDestroy() {
            clearInterval(this.interval)
            clearInterval(this.interval2)
        }
    }
</script>

<style lang="scss">
    .countdown {
        height: 200px;
        text-align: center;

        &__header {
            color: white;
            font-size: 35px;
            padding-top: 10px;
        }
    }

    @media only screen and (max-width: 1003px) {
        .countdown__header {
            font-size: 20px;
        }
    }
</style>
