<template>
    <div class="meta" v-if="!mobile">
        <transition name="fade">
            <div v-if="showCopyMessage" class="copyMessage">{{message}}</div>
        </transition>
        <div class="meta_buttons" v-if="var_view">
            <b-button style="width: 55px; margin-left: 2px; background-color: white; color: black;" v-no-focus @click="$emit('skip', button.val)" v-for="(button, i) in buttons" :key="i">
                {{ button.text }}
            </b-button>
            <b-button v-no-focus variant="outline-white" style="border: 1px solid black;" @click="slowDown()" class="ml-4">
                <b-icon icon="dash"></b-icon>
            </b-button>
            <b-select v-no-focus v-model="rate" @change="setRate($event)" style="width: 90px; color: black;" :options="speeds"></b-select>
            <b-button v-no-focus variant="outline-white" style="border: 1px solid black;" @click="speedUp()">
                <b-icon icon="plus"></b-icon>
            </b-button>
        </div>
        <div class="meta__header">{{eventName}}</div>
        <div v-if="allowSharing && !var_view" size="sm" pill class="meta__shareBtn mt-2" @click="shareBoxOpen = true">
            <b-icon icon="share"></b-icon>
            <span :style="{'margin-left': '5px'}"> {{ languages[lang]['share'] }} </span>
        </div>
        <div class="pt-3 ml-4" v-if="showSubtitles && !mobile && !var_view" style="display: inline-block; margin-top: -3px; vertical-align: top">
            <b-form-checkbox v-model="subtitleChecked" name="check-button" style="text-align: center;" switch>
                {{subtitleChecked ? 'Tekstitykset: ON' : 'Tekstitykset: OFF'}}
            </b-form-checkbox>
        </div>
        <b-modal :title="languages[lang]['share']" v-model="shareBoxOpen" hide-backdrop>
            <div class="meta__share" v-if="shareBoxOpen && allowSharing" style="margin: 0 auto; height: 120px">
                <textarea class="textarea" name="" v-model="shareLink"></textarea>
                <b-button size="sm" pill variant="outline-secondary" @click="copyToClipBoard" class="copyToClipBoard ml-2 mt-2">
                    <b-icon icon="clipboard-plus"></b-icon>
                    {{ languages[lang]['copy'] }}
                </b-button>
                <b-collapse v-model="showCopyCheck">
                    <b-icon icon="check" scale="2" style="color: green; margin-left: 36px;"></b-icon>
                </b-collapse>
            </div>
            <template v-slot:modal-footer>
                <b-button size="sm" pill @click="shareBoxOpen = false">OK</b-button>
            </template>
        </b-modal>
        <!-- <div class="toggleBar" v-show="!mobile && !hideArrow && showArrow">
            <img class="arrowImg"
                @click="$emit('toggle-side-bar')" 
                src="../assets/arrow-right.png"
                :class="[sideBarOpen ? 'goingRight' : 'goingLeft']"
            >
        </div> -->
        <vue-markdown v-if="!var_view" class="meta__description" :source="eventDescription"></vue-markdown>
        <div id="poweredBy" v-if="!var_view" @click="openKepit">
            <div>Powered by </div>
            <img :style="{height: '25px'}" src="../assets/kepitlogo.png">
        </div>
    </div>
</template>

<script>
    import VueMarkdown from 'vue-markdown'
    import { mapGetters } from 'vuex'
    // import Footer from './Footer.vue'

    export default {
        props: [
            'var_view',
            'eventData',
            'sideBarOpen',
            'mobile',
            'hideArrow',
            'subtitleOn'
        ],
        components: {
            VueMarkdown,
            // Footer
        },
        created() {
            this.showArrow = true;
            // try {
            //     console.log('Events: ', this.$store.state.customerData.events)
            //     if(!this.eventData.meta.showChat && this.$store.state.customerData.events.length <= 1) {
            //         this.showArrow = false;
            //     }
            // } catch {
            //     this.showArrow = true;
            // }
        },
        mounted() {
            document.querySelectorAll('a').forEach(el => el.target = "_blank")
        },
        data() {
            return {
                shareBoxOpen: false,
                shareLink: window.location.href,
                showCopyMessage: false,
                message: '',
                showArrow: true,
                showCopyCheck: false,
                buttons: [
                    {text: '-60', val: -60},
                    {text: '-10', val: -10},
                    {text: '-1', val: -1},
                    {text: '-.1', val: -0.1},
                    {text: '-.02', val: -0.02},
                    {text: '+.02', val: 0.02},
                    {text: '+.1', val: 0.1},
                    {text: '+1', val: 1},
                    {text: '+10', val: 10},
                    {text: '+60', val: 60},
                ],
                speeds: [
                    {text: 'x 1/10', value: 0.1},
                    {text: 'x 1/5', value: 0.2},
                    {text: 'x 1/2', value: 0.5},
                    {text: 'x 1', value: 1},
                    {text: 'x 2', value: 2},
                    {text: 'x 4', value: 4},
                    {text: 'x 8', value: 8}
                ],
                rate: 1
            }
        },
        methods: {
            speedUp() {
                let i = this.speeds.findIndex(speed => { return speed.value == this.rate })
                if(i == this.speeds.length - 1) return
                let speed = Math.max( this.speeds[0].value, this.speeds[i + 1].value)
                this.setRate(speed)
            },
            slowDown() {
                let i = this.speeds.findIndex(speed => { return speed.value == this.rate })
                if(i == 0) return
                this.setRate( this.speeds[i - 1].value )
            },
            setRate(rate) {
                this.$emit('change_rate', rate)
                this.rate = rate;
            },
            openKepit() {
                window.open("https://kepit.fi", "_blank");   
            },
            copyToClipBoard () {
                let copyText = document.querySelector('.textarea')
                copyText.select();
                copyText.setSelectionRange(0, 99999); /* Kännyköille */
                document.execCommand('copy');
                // this.flashCopyMessage();
                this.showCopyCheck = true;
                setTimeout(() => {
                    this.showCopyCheck = false;
                }, 2000)
                copyText.blur();
                copyText.setSelectionRange(0, 0); /* Kännyköille? */
            },
            flashCopyMessage() {
                this.message = 'Copied to clipboard!'
                this.showCopyMessage = true;
                setTimeout(() => {this.showCopyMessage = false}, 1000)
            }
        },
        computed: {
            ...mapGetters([
                'lang',
                'eventLang',
                'usableLanguages'
            ]),
            languages() {
                return this.$store.state.eventLanguages
            },
            eventName() {
                try {
                    return this.eventData.meta.language[this.eventLang].name;
                } catch {
                    return ''
                }
            },
            eventLang() {
                return this.lang
            },
            eventDescription() {
                try {
                    return this.eventData.meta.language[this.eventLang].description;
                } catch {
                    return ''
                }
            },
            allowSharing() {
                try {
                    return this.eventData.meta.allow_sharing;
                } catch {
                    return false;
                }
            },
            showSubtitles() {
                try {
                    return this.eventData.meta.subtitle_url
                } catch {
                    return false;
                }
            },
            subtitleChecked: {
              get () { return this.subtitleOn },
              set (value) { this.$emit('toggle-cc', value) },
            }
        },
        watch: {
            '$route': {
                handler() {
                    this.shareLink = window.location.href;
                },
                deep: true
            },
            // 'eventDescription': {
            //     handler() {
            //         alert('yeet')
            //         // document.querySelectorAll('a:not([target])').forEach(el => el.target = "_blank")
            //         document.querySelectorAll('a').forEach(el => el.target = "_blank")
            //     },
            //     deep: true
            // }
        }
    }
</script>

<style lang="scss">
    textarea {
        resize: none !important;
    }

    #poweredBy {
        display: flex;
        margin-top: 10px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
        width: 140px;
        justify-content: space-around;
        padding: 2px 5px;
        border-radius: 8px;
        cursor: pointer;
        transition-duration: 0.2s;
        color: grey;

        &:hover {
            box-shadow: 0 14px 28px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.12);
        }
    }

    .modal-content {
        z-index: 99999999 !important;
    }

    .meta {
        padding: 15px;

        &__header {
            font-size: 30px;
            display: inline-block;
            margin-right: 20px;
            max-width: 93%;
        }

        &_buttons {
            display: flex;
            justify-content: center;
        }

        &__shareBtn {
            // height: 23px;
            // border: 1px solid black;
            // display: inline-block;
            // width: 60px;
            // text-align: center;
            vertical-align: top;
            display: inline-block;
            border-radius: 4px;
            cursor: pointer;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            transition-duration: 0.2s;
            padding: 5px 10px;
            margin-top: -3px;

            // &:hover {
            //     box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
            // }
        }

        &__description {
            color: grey;
            margin-top: 2px;
            padding-top: 12px;
            max-width: 600px;
        }

        &__share {
            width: 100%;
            max-width: 400px;
            height: 70px;

            textarea {
                height: 70px;
                width: 80%;
                max-width: 500px;
                border-radius: 3px;
                padding: 10px;
                // text-align: center;
            }

            textarea:focus {
                outline: none;
            }

            .copyToClipBoard {
                // display: inline-block;
                vertical-align: top;
                // border: 1px solid black;
                // height: 50px;
                // width: 50px;
                // padding-top: 11px;
                // text-align: center;
                // border-radius: 5px;
                // margin-left: 4px;
                // cursor: pointer;
                // transition-duration: 0.2s;
            }

            .copyToClipBoard:hover {
                background-color: black;
                color: white;
                transform: scale(1.1, 1.1);
            }

        }

        .checkBoxes {
            padding-top: 13px;
            display: inline-block;
        }

        .checkBox {
            height: 30px;
            width: 30px;
            border: 1px solid black;
            border-radius: 4px;
            margin: 5px;
            text-align: center;
            background-color: white;
            padding-top: 4px;
            cursor: pointer;
            transition-duration: 0.2s;
        }

        .checkBox:hover {
            transform: scale(1.05, 1.05)
        }

        .copyMessage {
            position: absolute;
            top: 100;
            z-index: 2;
            width: 300px;
            height: 40px;
            border-radius: 10px;
            text-align: center;
            background-color: white;
            -webkit-box-shadow: 0px 0px 31px 4px rgba(0,0,0,0.12);
            -moz-box-shadow: 0px 0px 31px 4px rgba(0,0,0,0.12);
            box-shadow: 0px 0px 31px 4px rgba(0,0,0,0.12);
            padding-top: 10px;
        }
    }

    .fade-enter {
        opacity: 0;
        transition-duration: 1s;
    }

    .fade-enter-active {
        opacity: 1;
    }

    .toggleBar {
        display: inline;
        float: right;
        transition-duration: 0.3s;

        .arrowImg {
            height: 50px;
            cursor: pointer;
            transition-duration: 0.3s;
        }

        &:hover {
            transform: scale(1.2, 1.2)
        }
    }

    .goingLeft {
        -webkit-transform:rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        cursor: pointer;
    }
</style>
