import fetch from '../deps/fetchWithTimeout'

export default {
    methods: {
        async srvTime() {
            try {
                const response = await fetch('https://worldtimeapi.org/api/timezone/Europe/Helsinki', {}, 5000)
                const data = await response.json();
                return data.unixtime * 1000;
            } catch {
                return Date.now()
            }
        }
    }
}