<template>
    <div class="loadingDots" :style="style">{{loadingText}}</div>
</template>

<script>
    export default {
        props: {
            'fontSize': {
                type: String,
                required: false,
                default: '10px'
            },
            'color': {
                type: String,
                required: false,
                default: 'black'
            }
        },
        data() {
            return {
                loadingIndex: 0,
                loadingInterval: '',
                chatIsLoading: true
            }
        },
        created() {
            this.startLoading()
        },
        methods: {
            startLoading() {
                this.chatIsLoading = true;
                this.loadingInterval = setInterval(() => {
                    this.loadingIndex = this.loadingIndex < 3 ? this.loadingIndex + 1 : 1;
                }, 200)
            },
            stopLoading() {
                this.chatIsLoading = false;
                clearInterval(this.loadingInterval);
            },
        },
        computed: {
            style() {
                return {
                    'font-size': this.fontSize,
                    'color': this.color
                }
            },
            loadingText() {
                var string = '';
                for(let i = 0; i < this.loadingIndex; i++) {
                    string += '.'
                }
                return string;
            },
        },
        beforeDestroy() {
            this.stopLoading();
        }
    }
</script>

<style>

</style>
