export default {
    methods: {
        log(logText) {
            if(process.env.NODE_ENV == 'development' || this.$route.query.logs) {
                console.log(logText)
            }
        },
        permaLog() {
            // Permanent log
        }
    }
}