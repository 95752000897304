export default {
    data() {
        return {
            customerData: undefined
        }
    },
    created() {
        if(this.$store.state.customerId == this.$route.params.id && Object.keys(this.$store.state.customerData).length > 0) {
            this.customerData = this.$store.state.customerData;
        } else {
            this.fetchCustomerData();
        }
    },
    methods: {
        fetchCustomerData() {
            console.log('Customer data fetched')
            const url = `${this.$store.state.apiUrl}/${this.$route.params.id}.json`
            this.$store.state.customerId = this.$route.params.id;
            this.$http
                .get(url)
                .then(data => {
                    this.customerData = data.body;
                    this.$store.commit('newCustomerData', data.body);
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
}