import Vue from 'vue'
import VueResource from 'vue-resource';
import Vuex from 'vuex'
import axios from 'axios'

Vue.prototype.$http = axios
Vue.use(Vuex)
Vue.use(VueResource)

export default new Vuex.Store({
    state: {
        customerId: '',
        viewerId: '',
        oneclip: false,
        customerData: {},
        starttimeSet: false,
        eventData: {},
        eventsObject: [],
        event_opened: false,
        lang: 'fi',
        player_only: false,
        apiUrl: 'https://d261vut6jsk2c7.cloudfront.net/customers',
        webSocketUrlInitial: 'https://8p3dbld6s9.execute-api.eu-central-1.amazonaws.com/dev/messages?eventid=',
        webSocketUrl: "wss://r01g1z3881.execute-api.eu-central-1.amazonaws.com/dev?authToken=placeholder&eventid=",
        // process.env.NODE_ENV
        eventsLanguages: {
            "en": {
                "menneet": "PAST BROADCASTS",
                "tulevat": "UPCOMING",
                "live": "LIVE"
            },
            "sv": {
                "menneet": "TIDIGARE SÄNDNINGAR",
                "tulevat": "KOMMANDE",
                "live": "LIVE"
            },
            "fi": {
                "menneet": "MENNEET",
                "tulevat": "TULEVAT",
                "live": "LIVE"
            }
        },
        eventLanguages: {
            "en": {
                "aloita": "Start",
                "koodi": "Embed code",
                "aloita_alusta": "From 00:00",
                "aloita_kohdasta": "Start from",
                "kohdasta": "From",
                "pieni": "Small",
                "medium": "Medium",
                "suuri": "Large",
                "tama_lahetys": "This broadcast",
                "kaikki_lahetykset": "All broadcasts",
                "etusivu_text": "Home page",
                "lang": "English",
                "share": "Share",
                "embed": "Embed",
                "alkaa": "Starts at",
                "linkki": "Share link",
                "koko": "Size",
                "uusia": "New messages",
                "laheta": "Send",
                "nimi": "Username",
                "viesti": "Message",
                "copy": "Copy",
                "open_sidebar": "Open sidebar",
                "close_sidebar": "Close sidebar",
                "welcome": "Welcome to the chat!",
            },
            "sv": {
                "aloita": "Börjä",
                "koodi": "Bädda in kod",
                "aloita_alusta": "Börjä om",
                "aloita_kohdasta": "Börja på",
                "kohdasta": "På",
                "pieni": "Små",
                "medium": "Medium",
                "suuri": "Stor",
                "tama_lahetys": "Den här sändningen",
                "kaikki_lahetykset": "Alla sändningar",
                "etusivu_text": "Startsida",
                "lang": "Svenska",
                "share": "Dela",
                "embed": "Bädda in",
                "alkaa": "Börjar",
                "linkki": "Dela link",
                "koko": "Storlek",
                "uusia": "Nya meddelandena",
                "laheta": "Skicka",
                "nimi": "Namn",
                "viesti": "Meddelande",
                "copy": "Kopiera",
                "open_sidebar": "Öppna",
                "close_sidebar": "Stänga",
                "welcome": "Välkommen till chat!",
            },
            "fi": {
                "aloita": "Aloita",
                "koodi": "Upotuskoodi",
                "aloita_alusta": "Aloita alusta",
                "aloita_kohdasta": "Aloita kohdasta",
                "kohdasta": "Kohdasta",
                "pieni": "Pieni",
                "medium": "Medium",
                "suuri": "Iso",
                "tama_lahetys": "Tämä lähetys",
                "kaikki_lahetykset": "Kaikki lähetykset",
                "etusivu_text": "Etusivu",
                "lang": "Suomi",
                "share": "Jaa",
                "embed": "Upota",
                "alkaa": "Lähetys alkaa",
                "linkki": "Jako",
                "koko": "Koko",
                "uusia": "Uusia viestejä",
                "laheta": "Lähetä",
                "nimi": "Käyttäjänimi",
                "viesti": "Viesti",
                "copy": "Kopioi",
                "open_sidebar": "Avaa sivupalkki",
                "close_sidebar": "Sulje sivupalkki",
                "welcome": "Tervetuloa chattiin!",
            }
        }
    },
    mutations: {
        event_opened(state) {
            state.event_opened = true
        },
        newCustomerData(state, data) {
            state.customerData = data;
        },
        newCustomerId(state, id) {
            state.customerId = id;
        },
        setEventsObject(state, data) {
            state.eventsObject = data;
        },
        setEventData(state, event) {
            state.eventData = event
        },
        changeLang(state, payload) {
            state.lang = payload
        },
        setStarttime(state) {
            state.starttimeSet = true;
        },
        leaveEvent(state) {
            state.starttimeSet = false;
        },
        setViewerId(state, id) {
          state.viewerId = id
        }
    },
    actions: {
    },
    getters: {
        eventsLanguages(state) {
            return state.eventsLanguages;
        },
        eventData: (state) => state.eventData,
        eventLanguages(state) {
            return state.eventLanguages;
        },
        usableLanguages: (state, getters) => {
            let langOject =  getters.eventData?.meta?.language
            return langOject ? Object.keys(langOject) : []
        },
        lang(state) {
            if(['en', 'fi', 'sv'].includes(state.lang)) return state.lang
            return 'fi'
        },
        eventLang(state, getters) {
            if(['en', 'fi', 'sv'].includes(state.lang) && getters.usableLanguages.includes(state.lang)) return state.lang
            return getters.usableLanguages[0]
        },
        starttimeSet(state) {
            return state.starttimeSet
        },
        event_opened: state => { return state.event_opened },
        eventsObject: state => { return state.eventsObject },
        viewerId: state => { return state.viewerId }
    },
    modules: {}
})
