<template>
    <div class="chatCont" :style="{...chatContStyle, 'height': height}">
        <div class="newMessagesAppear">
            <div v-show="newMessagesDown" @click="scrollMessagesDown" class="newMessagesAppear__text">{{ languages[currentLang].uusia }}</div>
        </div>
        <div @scroll="chatScroll" ref="messagesList" class="messagesList">
            <LoadingIndicator v-if="chatIsLoading" fontSize="50px"></LoadingIndicator>
            <div class="message" v-if="!chatIsLoading" :style="messageFont" :class="{adminMessageStyle: message['moderator'] == 'true'}">
                <strong class="username">{{ languages[currentLang].welcome }}</strong>
            </div>
            <div class="message" :style="messageFont" :class="{adminMessageStyle: message['moderator'] == 'true'}" v-for="(message, i) in allMessages" :key="i">
                <strong class="username">{{message.username}}</strong>
                <vue-markdown v-if="message['moderator'] == 'true'" :source="message.message"></vue-markdown>
                <span v-else>  {{message.message}}</span>
            </div>
        </div>

        <div class="chat__controls">
            <span  v-if="chatFailure" class="m-2">Chatissa ilmeni ongelma. Yritä päivittää sivu myöhemmin.</span>
            <input v-if="!chatFailure" v-model="username" type="text" :placeholder="languages[currentLang].nimi">
            <input v-if="!chatFailure" v-model="message" type="text" :placeholder="languages[currentLang].viesti">
            <div>
                <div v-if="fs" style="margin-top: 8px; display: inline-block;">
                    <b-button @click="smallerFont()" size="sm" variant="light">
                        <b-icon icon="dash"></b-icon>
                    </b-button>
                    <b-button @click="largerFont()" class="ml-1" size="sm" variant="light">
                        <b-icon icon="plus"></b-icon>
                    </b-button>                
                </div>
                <div v-if="!chatFailure" @click="sendMessage()" class="sendMessage">{{ languages[currentLang].laheta }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import LoadingIndicator from './LoadingIndicator.vue'
    import VueMarkdown from 'vue-markdown'
    import ReconnectingWebSocket from 'reconnecting-websocket';
    import logger from '../mixins/logger.js'

    export default {
        mixins: [logger],
        props: ['fs'],
        data() {
            return {
                allMessages: [],
                height: '600px',
                newMessagesDown: false,
                socketIsOn: false,
                isScrolledToBottom: false,
                username: '',
                message: '',
                socket: undefined,
                chatIsLoading: false,
                pingInterval: undefined,
                counter: 0,
                firstOpenOfWebsocket: true,
                pingGotBack: true,
                failureCount: 0,
                chatFailure: false,
                chatFontSize: 25
            }
        },
        components: {
            LoadingIndicator,
            VueMarkdown
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', () => {
                    this.chatContHeight();
                    setTimeout(this.newMessagesPlace, 100)
                });
                this.chatContHeight();
                setTimeout(this.newMessagesPlace, 100);
                this.openWebsocket();
                this.getMessages();
                window.addEventListener('keypress', event => {
                    if (event.keyCode == 13) {
                        this.sendMessage();
                    }
                })
                this.startPings();
                // Logataan käyttäjän tulo chattiin
                // loggerJoined()
            })
        },
        computed: {
            currentLang() {
                return this.$store.state.lang
            },
            languages() {
                return this.$store.state.eventLanguages
            },
            chatContStyle() {
                return {
                    display: 'grid',
                    'z-index': '-1',
                    'grid-template-rows': '1fr 45px 45px 46px',
                    width: '97%',
                    padding: '10px'
                }
            },
            messageFont() {
                if(!this.fs) return {'font-size': '17px'}
                return {
                    'font-size': this.chatFontSize + 'px'
                }
            }
        },
        methods: {
            largerFont() {
                this.chatFontSize = Math.min(60, this.chatFontSize + 2)
            },
            smallerFont() {
                this.chatFontSize = Math.max(5, this.chatFontSize - 2)
            },
            startPings() {
                this.pingInterval = setInterval(this.sendPing, 5 * 1000);
            },
            sendPing() {
                if(!this.pingGotBack) {
                    this.socket.reconnect()
                    this.failureCount++;
                    if(this.failureCount >= 5) {
                        this.chatFailure = true;
                        // clearInterval(this.pingInterval)
                    }
                } else {
                    this.failureCount = 0;
                }
                this.pingGotBack = false;
                this.socket.send({
                    eventId: this.$route.params.eventid,
                    message: 'ping'
                });
            },
            chatContHeight() {
                if($(window).width() > 800 && !this.fs) this.height = $(window).height() - 100 + 'px';
                else if($(window).width() > 800) this.height = $(window).height() + 'px';
                else {
                    this.height = $(window).height() - $('#player').height() + 'px';
                }
            },
            getMessages() {
                this.startChatLoading()
                var url = this.$store.state.webSocketUrlInitial + this.$route.params.eventid;
                this.$http.get(url).then(response => {
                    this.allMessages = response.body.Items;
                    setTimeout(this.scrollMessagesDown, 100);
                    this.stopChatLoading();
                }, response => {
                    this.log('Error' + response)
                })
            },
            scrollMessagesDown() {
                this.$refs["messagesList"].scroll(0, 1000000)
            },
            openWebsocket() {
                this.log('Trying to open websocket connection.')
                const websocketUrl = `${this.$store.state.webSocketUrl}${this.$route.params.eventid}`
                const options = {debug: false}
                this.socket = new ReconnectingWebSocket(websocketUrl, [], options);
                this.socket.reconnectInterval = 3 * 1000;

                this.socket.addEventListener('open', () => {
                    if(!this.firstOpenOfWebsocket) {
                        this.log('WebSocket restarted and messages fetched again')
                        this.getMessages();
                    }
                    this.firstOpenOfWebsocket = false
                });

                this.socket.addEventListener('message', (event) => {
                    this.handleNewMessage(event);
                })

                this.socket.addEventListener('close', (event) => {
                    this.log('Websocket was closed', event)
                })

                this.socket.addEventListener('error', (err) => {
                    this.log('An error occurred in the websocket: ', err)
                });
            },
            handleNewMessage(event) {
                // alert(event.data)
                
                if(JSON.parse(event.data).message && JSON.parse(event.data).message == 'pong') {
                    this.chatFailure = false;
                    this.pingGotBack = true;
                    return;
                }

                this.log(event.data)

                let isScrolledToBottom;
                try {
                    var out = document.querySelector(".messagesList");
                    isScrolledToBottom = out.scrollHeight - out.clientHeight <= out.scrollTop + 1;
                } catch {
                    isScrolledToBottom = false;
                    this.log("Could'nt scroll the chat")
                }

                if(JSON.parse(event.data).type == 'delete') this.deleteOrder(JSON.parse(event.data).messageId)
                else if (JSON.parse(event.data).type == 'message') this.allMessages = this.allMessages.concat([JSON.parse(event.data)])

                if (isScrolledToBottom) {
                    this.newMessagesDown = false;
                    setTimeout(this.scrollMessagesDown, 100)
                } else this.newMessagesDown = true;     
            },
            startChatLoading() {
                this.chatIsLoading = true;
                this.loadingInterval = setInterval(() => {
                    this.loadingIndex = this.loadingIndex < 3 ? this.loadingIndex + 1 : 1;
                }, 200)
            },
            stopChatLoading() {
                this.chatIsLoading = false;
                clearInterval(this.loadingInterval);
            },
            sendMessage() {
                if(this.username.trim() == '') this.username = 'Nimetön'
                var message = {
                    "action": "sendmessage",
                    "eventId": this.$route.params.eventid,
                    "data": {
                        "username": this.username,
                        "message": this.message
                    }
                }
                if(this.message.trim() != '') this.socket.send(JSON.stringify(message));
                this.message = '';
            },
            newMessagesPlace() {
                try {
                    let min = this.fs ? 240 : 191
                    document.querySelector('.newMessagesAppear').style.marginTop = this.height.split('p')[0] - min + 'px';
                    document.querySelector('.newMessagesAppear').style.width = document.querySelector('.chatCont').offsetWidth + 'px';
                } catch(e) {
                    this.log('Caught an error that doesnt affect anything: ', e)
                }
            },
            chatScroll: function() {
                var outi = document.querySelector('.messagesList');
                var scrolledDown = outi.scrollHeight - outi.clientHeight <= outi.scrollTop + 5;

                if (scrolledDown) this.newMessagesDown = false;
                // else this.newMessagesDown = true;
            },
            deleteOrder(id) {
                for(let i = 0; i < this.allMessages.length; i++) {
                  if(this.allMessages[i].messageId == id) {
                     this.allMessages.splice(i, 1);
                     break;
                  }
                }
              }
        },
        beforeDestroy() {
            this.socket.close()
            clearInterval(this.pingInterval)
        }
    }
</script>

<style lang="scss">
    .messagesList {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .message {
        padding-left: 4px;
        cursor: default;
        max-width: 100%;
        font-size: 16px;

        span {
            max-width: 300px !important;
            white-space: pre-line;
            width: 300px;
        }
    }

    .adminMessageStyle {
        background-color: lightgrey;
        border-radius: 5px;
    }

    .chatCont input {
        outline: none;
        border: none;
        width: 100%;
        height: 40px;
        padding-left: 15px;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition-duration: 0.2s;
        font-size: 16px;
        margin-top: 3px;
    }

    .chatCont input:focus {
        background-color: rgba(0, 0, 0, 0);
        border: 2px solid grey;
    }

    .chatCont .sendMessage {
        height: 40px;
        width: 80px;
        float: right;
        background-color: rgba(0, 0, 0, 0.5);
        margin: 3px;
        text-align: center;
        padding-top: 7px;
        cursor: pointer;
        transition-duration: 0.2s;
        border-radius: 6px;
        color: white;
        font-weight: bold;
    }

    .chatCont .sendMessage:hover {
        transform: scale(1.05, 1.05);
        background-color: rgba(0, 0, 0, 0.6);
    }

    .sendMessage {
        height: 40px;
        width: 80px;
        float: left;
        background-color: rgba(0, 0, 0, 0.5);
        margin: 3px;
        text-align: center;
        padding-top: 7px;
        cursor: pointer;
        transition-duration: 0.2s;
        border-radius: 6px;
        color: white;
        font-weight: bold;
    }

    .newMessagesAppear {
        display: inline;
        grid-row-start: 2;
        grid-row-end: 3;
        position: absolute;
        // margin-top: 5px;
        z-index: 1;
        height: 30px;
        text-align: center;
        cursor: pointer;

        &__text {
            width: 160px;
            color: white;
            background-color: rgba(26, 20, 20, 0.5);
            margin: 0 auto;
            height: 100%;
            padding-top: 2px;
            border-radius: 5px;
        }
    }

    .loadingDots {
        text-align: center;
        font-size: 40px;
    }
</style>
