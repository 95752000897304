<template>
    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-6" style="padding: 20px; display: inline-block;">
        <div class="homePageCardly" @click="openEvent(event.uuid)">
            <div class="homePageCardly__imgCont">
                <img class="cardly-img" :src="posterUrl" :alt="event.meta.language[this.eventLanguage].name">
            </div>
            <div class="homePageCardly__header">{{eventName(event.meta.language[this.eventLanguage].name)}}</div>
            <div class="homePageCardly__meta">{{getStartDate(event.start_time)}}    {{getStartTime(event.start_time)}}</div>
        </div>
    </div>
</template>

<script>
    import posterMixin from "../mixins/posterMixin"

    export default {
        props: ['event'],
        mixins: [posterMixin],
        data() {
            return {
            }
        },
        methods: {
            getStartDate(unix) {
                var a = new Date(unix * 1000);
                return a.getDate() + '.' + (1 + a.getMonth()) + '.' + a.getFullYear();
            },
            getStartTime(unix) {
                var a = new Date(unix * 1000);
                return a.toString().substring(15, 21)
            },
            openEvent(uuid) {
                this.$router.push({ 
                    path: `${this.$route.path}/e/${uuid}`,
                    query: {
                        lang: this.$route.query.lang
                    }
                })
            },
            eventName(name) {
                let max_length = 45;
                return name.length > max_length ? `${name.substr(0, max_length)}...` : name
            }
        },
        computed: {
            eventLanguage() {
                return Object.keys(this.event.meta.language)[0]
            },
            name() {
                return this.event.meta.language[this.eventLanguage].name
            }
        }
    }
</script>

<style lang="scss">
    .homePageCardly {
        height: 200px;
        width: 100%;
        // display: inline-grid;
        display: inline-flex;
        flex-direction: column;
        color: black;
        border-radius: 6px;
        grid-template-rows: 7.5fr 1.5fr 1fr;
        transition-duration: 0.3s;
        cursor: pointer;
        text-align: center;
        // border: 1px solid black;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        background-color: white;

        &:hover {
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
            transform: rotateX(4deg);
        }

        &__imgCont {
            height: 90%;
            max-height: 150px;
            margin-top: 5px;
            width: 100%;
            overflow: hidden;
            text-align: left;
            border-radius: 5px;
            pointer-events: none;
            position: relative;
            text-align: center;
            display: flex;
            justify-content: center;
            border-radius: 10px;

            img {
                height: 100%;
                width: auto;
                // max-width: 100%;
                // height: auto;
                // max-height: 115px;
                // height: 100%;
                // border-radius: 5px;
                // max-width: 100%;
            }
        }

        &__header {
            text-align: center;
            padding: 0 4px;
            pointer-events: none;
            grid-row-start: 2;
            grid-row-end: 3;
            // white-space: nowrap;
            // overflow: hidden;
        }

        &__meta {
            grid-row-start: 3;
            grid-row-end: 4;
            pointer-events: none;
            text-align: center;
            font-size: 0.7em;
            color: rgba(0, 0, 0, 0.4);
        }
    }

    .homePageCardly:hover {
        // -moz-box-shadow: 3px 3px 5px 5px rgba(180, 180, 180, 0.2);
        // -webkit-box-shadow: 3px 3px 5px 5px rgba(180, 180, 180, 0.2);
        // box-shadow: 3px 3px 5px 5px rgba(180, 180, 180, 0.2);
        // transform: scale(1.05, 1.05)
    }
</style>