export default {
    computed: {
        posterUrl() {
            try {
                return this.event.meta.poster.file ? this.event.meta.poster.file : this.getCustomerPoster();
            } catch {
                return this.getCustomerPoster();
            }
        },
    },
    methods: {
        getCustomerPoster() {
            try {
                return this.$store.state.customerData.meta.default_poster ? this.$store.state.customerData.meta.default_poster : require('../assets/default.png');
            } catch {
                return require('../assets/default.png');
            }
        }
    }
}