<template>
    <div class="videoCardRight" :class="[var_view ? 'var_cardly' : 'cardly', is_chosen ? 'active' : '']" :style="card_style" @click="openEvent(event.uuid)">
        <div v-if="!var_view" class="img-cont" style="display: flex; justify-content: center;">
            <img class="cardly-img" :src="posterUrl">
            <div v-if="event.type=='live'" class="liveTag">Live
                <div class="redCircle blinking"></div>
            </div>
        </div>
        <div :class="[var_view ? 'var-cardly-meta-box' : 'cardly-meta-box']">
            <div>
                <div :class="[var_view ? 'var-cardly-header' : 'cardly-header']">
                    {{eventName(event.meta.language[eventLanguage].name)}}
                </div>
                <div :class="[var_view ? 'var-cardly-meta' : 'cardly-meta']">
                    <div class="cardly-meta-time">{{getStartTime(event.start_time)}} • {{getStartDate(event.start_time)}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import posterMixin from "../mixins/posterMixin"

    export default {
        props: ['event', 'var_view', 'is_chosen'],
        mixins: [posterMixin],
        computed: {
            eventLanguage() {
                return Object.keys(this.event.meta.language)[0]
            },
            card_style() {
                if(this.var_view) return {height: '50px'}
                else return {height: '120px'}
            }
        },
        methods: {
            getStartDate(unix) {
                var a = new Date(unix * 1000);
                return a.getDate() + '.' + (1 + a.getMonth()) + '.' + a.getFullYear();
            },
            getStartTime(unix) {
                var a = new Date(unix * 1000);
                return a.toString().substring(15, 21)
            },
            openEvent(uuid) {
                if(uuid !== this.$route.params.eventid) {
                    this.$router.push({ 
                        path: `/c/${this.$store.state.customerId}/e/${uuid}`,
                        query: {
                            lang: this.$route.query.lang,
                            view: this.var_view ? 'var' : undefined
                        }
                    })
                }
                this.$emit('eventOpened')
            },
            eventName(name) {
                let max_length = 40;
                return name.length > max_length ? `${name.substr(0, max_length)}...` : name
            }
        }
    }
</script>

<style lang="scss">
    .active {
        background-color: lightgrey;
    }
    .cardly {
        width: 85%;
        // display: inline-grid;
        display: flex;
        color: black;
        border-radius: 6px;
        grid-template-columns: 35% 1fr;
        grid-template-rows: 70% 30%;
        transition-duration: 0.25s;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        margin: 25px auto;

        &-header {
            font-size: 15px;
            padding-left: 10px;
            padding-top: 2px;
        }

        &-meta {
            color: grey;
            font-size: 13px;
            padding-left: 10px;
        }

        &-meta-box {
            overflow: hidden; 
            display: flex; 
            height: 100%; 
            justify-content: center; 
            width: 50%; 
            flex-direction: column; 
            padding: 10px;
        }

        .img-cont {
            height: 100%;
            width: 50%;
            overflow: hidden;
            text-align: left;
            border-radius: 5px;
            pointer-events: none;

            img {
                width: auto;
                height: 100%;
                border-radius: 5px;
            }
        }

        &:hover {
            cursor: pointer;
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
            transform: rotateX(4deg);
        }
    }

    .var-cardly {
        width: 85%;
        // display: inline-grid;
        display: flex;
        cursor: pointer;
        color: black;
        border-radius: 6px;
        grid-template-columns: 1fr;
        grid-template-rows: 70% 30%;
        transition-duration: 0.25s;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        margin: 25px auto;

        &-header {
            font-size: 15px;
            padding-left: 10px;
            padding-top: 2px;
        }

        &-meta {
            color: grey;
            font-size: 10px;
            color: grey;
            padding-left: 10px;
        }

        &-meta-box {
            overflow: hidden; 
            display: flex; 
            height: 100%; 
            justify-content: center; 
            cursor: pointer;
            width: 100%; 
            flex-direction: column; 
            padding: 0px;
            border: 1px solid grey;
            margin: 3px 0;
            border-radius: 4px;
            transition-duration: 0.3s;

            &:hover {
            border-radius: 10px;

                background-color: rgba(0,0,0,0.05)
            }
        }

        .img-cont {
            height: 100%;
            width: 50%;
            overflow: hidden;
            text-align: left;
            border-radius: 5px;
            pointer-events: none;

            img {
                width: auto;
                height: 100%;
                border-radius: 5px;
            }
        }

        &:hover {
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
            transform: rotateX(4deg);
        }
    }
</style>