<template>
    <div class="sideBar">
        <div class="videoListHeading" v-if="!var_view">
            <div @click="openThisTab(0)" v-if="showEvents" id="referenceBox" :class="{'videoListHeadingBlock--chosen': isThisTabOpen(0)}" class="videoListHeadingBlock"><img src="../assets/list-icon.png"></div>
            <div v-if="showChat" @click="openThisTab(1)" :class="{'videoListHeadingBlock--chosen': isThisTabOpen(1)}" class="videoListHeadingBlock"><img src="../assets/chat-icon.png"></div>
            <div v-if="mobile" @click="openThisTab(2)" :class="{'videoListHeadingBlock--chosen': isThisTabOpen(2)}" class="videoListHeadingBlock"><img src="../assets/info-icon.png"></div>
            <div v-if="showTags" @click="openThisTab(3)" :class="{'videoListHeadingBlock--chosen': isThisTabOpen(3)}" class="videoListHeadingBlock"><img src="../assets/tag-icon.png"></div>
            <div v-if="mobile && showSubtitles" @click="openThisTab(4)" :class="{'videoListHeadingBlock--chosen': isThisTabOpen(4)}" class="videoListHeadingBlock"><b-icon icon="badge-cc" scale="1.3"></b-icon></div>
        </div>
        <div v-if="var_view" style="padding: 10px; display: flex; justify-content: space-around;">
            <div style="padding-top: 16px; color: grey;">VAR powered by</div>
            <img src="../assets/kepit-systems.png" height="50px;">
        </div>
        <div class="barVideoList" style="padding-top: 10px" v-if="isThisTabOpen(0) && showEvents">
            <YouTubeCard
                :var_view="var_view"
                v-for="(event, i) in customerEvents"
                :key="i"
                :event="event"
                @eventOpened="openEvent()"
                :is_chosen="is_chosen(event.uuid)"
            ></YouTubeCard>
        </div>
        <EventStreamerChat
            :key="chatKey"
            v-if="isThisTabOpen(1) && showChat"
            @refreshChat="refreshChat()"
        ></EventStreamerChat>
        <EventMeta
            v-if="isThisTabOpen(2) && mobile"
            :sideBarOpen="sideBarOpen"
            :eventData="eventData"
            :mobile="!mobile"
            :hideArrow="true"
            :subtitleOn="subtitleOn"
            @toggle-cc="$emit('toggle-cc')"
        ></EventMeta>
        <TagBar
            :tags="tags"
            v-if="isThisTabOpen(3) && showTags"
            @go-to="$emit('go-to', $event)"
            @starttime="starttime_found($event)"
        ></TagBar>
        <div v-if="isThisTabOpen(4) && showSubtitles" class="mt-3">
            <b-form-checkbox @change="$emit('toggle-cc')" :value="subtitleOn" name="check-button" style="text-align: center;" switch>
                {{subtitleOn ? 'Tekstitykset: ON' : 'Tekstitykset: OFF'}}
            </b-form-checkbox>
        </div>
    </div>
</template>

<script>
    // import EventStreamerYouTube from './EventStreamerYouTube.vue'
    import YouTubeCard from './YouTubeCard.vue'
    import EventStreamerChat from './EventStreamerChat.vue'
    import EventMeta from '../components/EventMeta.vue';
    import TagBar from '../components/TagBar.vue';
    // import EventMeta from './EventMeta.vue'

    export default {
        props: [
            'var_view',
            'customerData',
            'mobile',
            'sideBarOpen',
            'eventData',
            'subtitleOn',
            'tags'
        ],
        data() {
            return {
                openTabVal: -1,
                messages: [],
                chatKey: 0,
                tagsKeys: 0
            }
        },
        components: {
            YouTubeCard,
            EventStreamerChat,
            TagBar,
            EventMeta
        },
        created() {
            if(this.var_view) this.openTabVal = 0;
        },
        methods: {
            is_chosen(uuid) {
                if(!this.eventData) return
                return this.eventData.uuid == uuid;
            },
            starttime_found(payload) {
                this.$emit('starttime', payload)
            },
            goTo(e) {
                alert(e)
            },
            refreshChat() {
                this.chatKey++;
            },
            openEvent() {
                this.$store.commit('leaveEvent')
                this.$emit('event-opened')
            },
            isUpcoming(event) {
                //TODO: Server time
                return event.starttime > Date.now();
            },
            openThisTab(num) {
                this.openTabVal = num;
            },
            isThisTabOpen: function(num) {
                return this.openTab == num;
            },
            scrollMessagesDown: function() {
                this.$refs["messagesList"].scroll(0, 1000000)
            },
            getTags() {
                this.$refs.tagbar.getTags()
            }
        },
        computed: {
            upcomingEvents() {
                if(this.events) {
                    var upcomings = [];
                    this.events.forEach((event) => {
                        if(!this.isUpcoming(event)) upcomings.push(event)
                    })
                    return upcomings
                } else return [];
            },
            videoListLineMargin() {
                var wid;
                try {
                    wid = document.getElementById('referenceBox').offsetWidth + 16;
                } catch {
                    wid = 0;
                }
                return {
                    'margin-left': (this.openTab * wid) + 15 + 'px'
                }
            },
            customerEvents() {
                let listed_events;
                if(this.customerData && this.eventData) {
                    if(this.var_view) 
                        listed_events = this.customerData.events.filter(e => {return !e.meta.is_unlisted})
                    else 
                        listed_events = this.customerData.events.filter(e => {return !e.meta.is_unlisted && this.eventData.uuid != e.uuid})
                    return listed_events.sort((a, b) => {
                        return b.kick_off_time - a.kick_off_time
                    });
                }
                return [];
            },
            showChat() {
                try {
                    return this.eventData.meta.show_chat;
                } catch {
                    return false;
                }
            },
            showSubtitles() {
                try {
                    return this.eventData.meta.subtitle_url
                } catch {
                    return false;
                }
            },
            showTags() {
                try {
                    return this.eventData.meta.show_tags;
                } catch {
                    return false;
                }
            },
            openTab() {
                //Jos chat käytössä -> Avaa chat
                //Chat ei käytössä ja tags -> avaa tags
                //Chat ei käytössä ja mobile ja ei tags -> avaa info
                //Chat ei käytössä ja ei mobile -> Sivubar kiinni, videolista
                if(this.openTabVal < 0) { 
                    if(this.showChat) return 1;
                    else if(this.showTags) return 3;
                    else if(this.mobile) return 2;
                    else return 0;
                } else {
                    return this.openTabVal;
                }
            },
            showEvents() {
                return true;
                // return this.customerEvents.length > 1
            }
        },
        watch: {
            'eventData': {
                handler() {
                    // this.tagsKeys = this.tagsKeys + 1;
                },
                deep: true
            }
        }
    }

</script>

<style lang="scss">
    .barVideoList {
        width: 95%;
        margin: 0 auto;
    }

    .sideBar {
        overflow-y: scroll;
    }

    .videoListHeading {
        height: 50px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        cursor: pointer;
        // top: 50;
        position: sticky; 
        top: 0px;
        background-color: white;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        z-index: 1000;
        padding-bottom: 3px;
        border-radius: 0 0 5px 5px;

        &Block {
            cursor: pointer;
            display: inline-block;
            width: 15%;
            margin: 0 4px;
            height: 100%;
            background-color: white;
            text-align: center;
            vertical-align: top;
            padding-top: 8px;
            transition-duration: 0.3s;

            img {
                height: 60%;
                transition-duration: 0.3s;
            }

            &--chosen {
                background-color: white;
                border-bottom: 3px solid lightgrey;

                img {
                    height: 70%;
                }
            }
        }   

        &Block:hover {
            img {
                transform: scale(1.2, 1.2);
            }
        }
    }
</style>
