<template>
    <div class="header">
        <div :style="backBtnStyle" @click="goToEvents" class="header__main">
            <p class="header__text" v-if="!isUnlisted">{{ languages[currentLang]['etusivu_text'] }}</p>
            <p class="header__arrow" v-if="!isUnlisted"><img src="../assets/arrow-right.png" alt=""></p>
        </div>
        <div class="header__languageCont">
            <div @click="changeLang('fi')" class="header__languageCont__language">FI</div>
            <div @click="changeLang('sv')" class="header__languageCont__language">SV</div>
            <div @click="changeLang('en')" class="header__languageCont__language">EN</div>
        </div>
        <!-- <b-icon @click="$emit('toggleCC')" style="cursor: pointer" icon="badge-cc"></b-icon> -->
        <div class="dropdown" @click="languagesOpen = true">
            <img src="../assets/language.png" alt="">
            <div class="dropdown__content" v-if="languagesOpen">
                <div @click.stop="changeLang('fi')" class="header__languageCont__language--hidden">FI</div>
                <div @click.stop="changeLang('sv')" class="header__languageCont__language--hidden">SV</div>
                <div @click.stop="changeLang('en')" class="header__languageCont__language--hidden">EN</div>
            </div>
        </div>
        <div @click="goToEvents" class="header__tag">
            <img v-if="showLogo" class="header__image" :src="customerLogoUrl">
            <div v-if="showName" class="header__name">{{customerName}}</div>
        </div>
    </div>
</template>

<script>
    // import store from '../store'
    import $ from 'jquery';

    export default {
        props: {},
        data() {
            return {
                oneclip: this.$store.state.oneclip,
                lang: this.$store.state.lang,
                showLogo: true,
                languagesOpen: false,
                showOptions: false
            }
        },
        methods: {
            changeLang(lang) {
                this.languagesOpen = false;
                this.$store.commit('changeLang', lang)
                // this.$store.state.lang = lang;
                if(lang != 'fi') this.$router.replace({ query: { lang: lang } })
                else this.$router.replace({ query: {} })
            },
            goToEvents() {
                if(this.isUnlisted) return
                this.$store.commit('leaveEvent')
                this.$router.push({
                    path: `/c/${this.$route.params.id}`, 
                    query: {
                        lang: this.$route.query.lang,
                    }
                })
            },
            getCustomerLogoUrl() {
                try {
                    return this.$store.state.customerData.meta.default_logo;
                } catch {
                    return '/img/icons/kepitlogo.png'
                }
            },
        },
        computed: {
            toMainString() {
                return '/c/' + this.$route.params.id;
            },
            currentLang() {
                return this.$store.getters.lang
            },
            languages() {
                return this.$store.getters.eventLanguages
            },
            isUnlisted() {
                try {
                    return this.$store.state.eventData.meta.is_unlisted
                } catch {
                    return false
                }
            },
            customerLogoUrl() {
                try {
                    if(this.$store.state.eventData.meta.logo != null) {
                        return this.$store.state.eventData.meta.logo.file
                    }
                    else return this.getCustomerLogoUrl()
                } catch {
                    return this.getCustomerLogoUrl()
                }
            },
            showName() {
                return this.getCustomerLogoUrl() == '/img/icons/kepitlogo.png';
            },
            customerName() {
                try {
                    return this.$store.state.customerData.meta.title;
                } catch {
                    return ''
                }
            },
            backBtnStyle() {
                if(this.$route.meta.hideBackButton) { 
                    return {
                        'opacity': 0,
                        'cursor': 'default'
                    } 
                }
                else {
                    return {
                        'opacity': 1,
                        'cursor': 'pointer'
                    }
                }
            },
            mobile() {
                return $(window).width() < 600
            }
        }
    }
</script>

<style lang="scss">
    .header {
        width: 100%;
        height: 50px;
        // border-bottom: 3px solid lightgrey;
        z-index: 2;
        position: fixed;
        top: 0;
        background-color: white;
        text-align: center;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        a {
            color: black;
        }

        &__main {
            float: left;
            padding-top: 2px;
            display: inline-block;
            height: 100%;
            background-color: lightgrey;
            text-align: center;
            width: 120px;
            border-radius: 3px;
            transition-duration: 0.2s;
            height: 60%;
            margin: 12px 0 0 11px;
            cursor: pointer;
        }

        &__main:hover {
            transform: scale(1.1, 1.1);
        }

        &__languageCont {
            font-size: 20px;
            margin-top: -22px;
            display: inline-block;
            float: right;
            margin-right: 10px;

            &__language {
                display: inline-block;
                position: static;
                font-size: 15px;
                width: 15px;
                margin-top: 32px;
                cursor: pointer;
                text-align: center;
                padding-left: -10px;
                width: 40px;
                height: 30px;
                line-height: 27px;
                border-radius: 3px;
                transition-duration: 0.2s;
                cursor: pointer;

                &--hidden {
                    display: inline-block;
                    position: static;
                    font-size: 15px;
                    width: 15px;
                    margin-top: 0px;
                    cursor: pointer;
                    text-align: center;
                    padding-left: -10px;
                    width: 40px;
                    height: 30px;
                    line-height: 27px;
                    border-radius: 3px;
                    transition-duration: 0.2s;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.2, 1.2);
                        background-color: lightgrey;
                    }
                }
            }

            &__language:hover {
                transform: scale(1.2, 1.2);
                background-color: lightgrey;
            }
        }

        &__tag {
            display: inline-block;
            height: 80%;
            margin-top: 5px;
            padding: 0 4px;
            border-radius: 10px;
            cursor: pointer;
            transition-duration: 0.2s;

            &:hover {
                transform: scale(1.1, 1.1);
            }
        }

        &__image {
            height: 40px;
        }

        &__name {
            display: inline-block;
            vertical-align: top;
            margin-left: 8px;
            font-size: 24px;
            padding-top: 4px;

        }

        &__arrow {
            display: none;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);  

            img {
                height: 20px;
                margin-top: -2px;
            }      
        }

        @media only screen and (max-width: 800px) {
            .header {
                &__main {
                    width: 30px;
                }

                &__text {
                    display: none;
                }

                &__arrow {
                    display: block;
                }

                &__name {
                    display: none;
                }

                &__languageCont {
                    display: none;
                }
            }

            .dropdown {
                display: block;
            }


        }
    }

    .dropdown {
        display: none;
        position: relative;
        float: right;
        cursor: pointer;

        &__content {
            // display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            padding: 12px 16px;
            z-index: 1;
            right: 0;
            height: 50px;
        }

        img {
            height: 30px;
            margin-top: 8px;
            margin-right: 18px;
            opacity: 0.6;
            transition-duration: 0.3s;

            &:hover {
                transform: scale(1.1, 1.1);
                opacity: 1;
            }
        }
    }
</style>
