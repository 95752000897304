<template>
    <div class="events container">
        <!-- {{eventsObject}} -->
        <b-spinner v-if="loading" style="margin: 0 auto;"></b-spinner>
        <div v-for="(block, index) in $store.state.eventsObject" :key="index" class="events__block">
          <div :id="block.element_id" class="events__header" v-if="filter_listeds(block.events).length > 0">
            <span>{{block.blockHeader}}</span>
          </div>
          <div class="row events__liha" style="display: flex">
            <EventCard v-for="event in filter_listeds(block.events)" :key="event.uuid" :event="event"></EventCard>
          </div>
        </div>
        <h5 v-if="$store.state.eventsObject.length == 0 && !loading" class="m-5">Ei vielä lähetyksiä</h5>
    </div>
</template>

<script>
    import EventCard from '../components/EventCard.vue'

    export default {
        components: {
            EventCard
        },
        data() {
            return {
                customerId: this.$route.params.id,
                customerData: undefined,
                preference: undefined,
                eventsObject: [{blockHeader: 'Ladataan', events: []}, {blockHeader: 'Ladataan', events: []}, {blockHeader: 'Ladataan', events: []}],
                loading: true,
                isExplorer: false
            }
        },
        beforeMount() {
            this.$store.commit('newCustomerId', this.$route.params.id)
        },
        created() {
            let isIe = this.$browserDetect.isIE
            if(isIe) {
                this.$router.push({
                    path: '/explorer'
                })
                return
            }

            if(this.$store.state.customerId == this.$route.params.id && Object.keys(this.$store.state.customerData).length > 0) {
                if(!this.customerData) {
                    this.customerData = this.$store.state.customerData;
                    
                    if(!this.$store.state.eventsOpened) {
                        this.handleCustomerPreferences(this.customerData, !this.$store.getters.event_opened);
                        this.loading = false;
                    }
                }
            } else {
                this.fetchCustomerData();
            }
        },
        methods: {
            filter_listeds(arr) {
                return arr.filter(e => {return !e.meta.is_unlisted})
            },
            isUpcoming(event) {
                //TODO: Server time
                return event.start_time > Math.floor(Date.now() / 1000);
            },
            fetchCustomerData() {
                const url = `${this.$store.state.apiUrl}/${this.$route.params.id}.json`
                this.$store.state.customerId = this.$route.params.id;
                fetch(url)
                    .then(response => {return response.json()})
                    .then(data => {
                        this.loading = false
                        console.log(data)
                        this.customerData = data;
                        this.$store.commit('newCustomerData', data)
                        this.handleCustomerPreferences(data, !this.$store.getters.event_opened)
                    })
                    .catch(err => {
                        console.log("Customer data fetch failed:", err)
                        this.loading = false
                    }) 
            },
            handleCustomerPreferences(data, firstTime = true) {
                let preference;
                try {
                    preference = data.meta.default_view;
                } catch {
                    preference = 'list_lives_before_vods'
                }

                this.preference = preference || 'list_lives_before_vods';

                let liveIndex = 0;
                let vodIndex = 1;

                // if(!this.preference) return [{blockHeader: 'Ladataan', events: []}, {blockHeader: 'Ladataan', events: []}, {blockHeader: 'Ladataan', events: []}];
                if(this.preference == 'list_lives_before_vods') {
                    liveIndex = 0;
                    vodIndex = 1;
                } else if (this.preference == 'list_vods_before_lives') {
                    liveIndex = 1;
                    vodIndex = 0;
                }
                
                let events = this.customerData.events
                
                let vods = events.filter(e => e.start_time < Date.now() / 1000 && e.end_time < Date.now() / 1000)
                let lives = events.filter(e => e.end_time > Date.now() / 1000);
                vods = this.filter_listeds(vods)
                lives = this.filter_listeds(lives)

                vods = vods.sort((a, b) => {return b.start_time - a.start_time})
                lives = lives.sort((a, b) => {return a.start_time - b.start_time})

                let skeleton = [{
                    blockHeader: '',
                    events: [],
                    element_id : ''
                },{
                    blockHeader: '',
                    events: [],
                    element_id : ''
                }]

                skeleton[liveIndex].blockHeader = this.languages[this.currentLang].tulevat
                skeleton[liveIndex].events = lives;
                skeleton[liveIndex].element_id = 'events-live';
                skeleton[vodIndex].blockHeader = this.languages[this.currentLang].menneet
                skeleton[vodIndex].events = vods;
                skeleton[vodIndex].element_id = 'events-vod';

                if(vods.length === 0) {
                    skeleton.splice(vodIndex, 1);
                } 
                if(lives.length === 0) {
                    skeleton.splice(liveIndex, 1);
                }

                this.$store.commit('setEventsObject', skeleton);

                if(events.length == 0) {
                    this.loading = false;
                    return;
                }

                if(preference == 'show_latest_vod' && firstTime) this.openEvent(vods[0].uuid)
                else if(preference == 'show_next_live' && firstTime) {
                    if(lives.length > 0) this.openEvent(lives[0].uuid)
                    else this.openEvent(vods[0].uuid)
                } else if (preference == 'show_latest_vod_unless_there_is_a_live_today' && firstTime) {
                    let twelveHours = 60 * 60 * 12;
                    if(lives.length > 0 && lives[0].start_time - twelveHours < Date.now() / 1000 ) this.openEvent(lives[0].uuid)
                    else this.openEvent(vods[0].uuid)
                }
            },
            openEvent(uuid) {
                this.$router.push({ 
                    path: `${this.$route.path}/e/${uuid}`,
                    query: {
                        lang: this.$route.query.lang
                    }
                })
            },
        },
        computed: {
            upcomingEvents() {
                if(this.customerData) {
                    var upcomings = [];
                    this.customerData.events.forEach((event) => {
                        if(this.isUpcoming(event)) upcomings.push(event)
                    })
                    return upcomings
                } else return [];
            },
            goneEvents() {
                if(this.customerData) {
                    var goneOrLive = [];
                    this.customerData.events.forEach((event) => {
                        if(!this.isUpcoming(event)) goneOrLive.push(event)
                    })
                    return goneOrLive
                } else return [];
            },
            currentLang() {
                return this.$store.getters.lang
            },
            languages() {
                return this.$store.getters.eventsLanguages
            }
        }
    }
</script>

<style  scoped lang="scss">
    .events {
        width: 100%;
        padding-top: 4em;
        margin-bottom: 50px;
        // background-color: rgba(0,0,0,0.1);

        &__header {
            cursor: default;
            font-size: 55px;
            color: grey;
            text-align: left;
        }

        &__block {
            width: 100%;
            margin: 0;
            margin-bottom: 40px;
        }
    }

    .container {
        min-height: 80vh;
    }

    @media only screen and (max-width: 400px) {
        .events__header {
            font-size: 30px;
        }
    }
</style>
