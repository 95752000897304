import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import browserDetect from "vue-browser-detect-plugin";
import FingerprintJS from '@fingerprintjs/fingerprintjs'

var nofocus = Vue.directive('no-focus', {
    bind: function(el) {
        el.addEventListener('focus', () => { el.blur() });
    }
})

Vue.use(browserDetect);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(nofocus)

Vue.config.productionTip = false

// Setup library to get browser id for viewer unique daily rotating hash
let fpPromise = null
try {
  fpPromise = FingerprintJS.load({monitoring: false})
} catch (error) {
  console.log('Browser id setup failed:', error)
}

(async () => {
  try {
    // Get browser id from library
    const fp = await fpPromise
    const result = await fp.get()

    store.commit('setViewerId', result.visitorId)
  } catch (error) {
    console.log('Browser id get failed:', error)

    // Getting browser id failed, fallback to session random id
    let id = ''
    const array = new Uint32Array(5)
    window.crypto.getRandomValues(array)
    for (const num of array) { id += num.toString() }

    store.commit('setViewerId', id)
  }
})()

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

