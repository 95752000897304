<template>
    <div v-if="showFooter" class="footer">
        <div class="footer__liha">
            <vue-markdown :source="footerText"></vue-markdown>
        </div>
    </div>
</template>

<script>
    import VueMarkdown from 'vue-markdown'

    export default {
        components: {VueMarkdown},
        computed: {
            footerText() {
                try {
                    return this.$store.state.customerData.meta.footer
                } catch {
                    return ''
                }
            },
            showFooter() {
                try {
                    return this.$store.state.customerData.meta.footer;
                } catch {
                    return true;
                }
            }
        }
    }
</script>

<style lang="scss">
    .footer {
        height: 200px;
        background-color: rgb(240, 240, 240);
        padding: 20px;
        margin-top: 40px;

        &__liha {
            width: 100%;
            max-width: 600px;
            text-align: center;
            margin: 0 auto;
        }
    }
</style>
