<template>
  <div id="app">
    <NavigationBar v-if="!player_only && !var_view"></NavigationBar>
    <router-view/>
    <FooterBar v-if="$route.meta.showFooter"></FooterBar>
  </div>
</template>

<script>
  import NavigationBar from './components/NavigationBar.vue'
  import FooterBar from './components/FooterBar.vue'
  import { mapMutations, mapGetters } from 'vuex'

  export default {
    created() {
      this.setLang()
    },
    components: {
      NavigationBar,
      FooterBar
    },

    data() {
      return {
        customerView: ""
      }
    },
 
    methods: {
      setLang() {
        const lang = this.$route.query.lang
        if(lang) this.changeLang(lang);
      },
      ...mapMutations([
        'changeLang'
      ])
    },
    
    computed: {
      ...mapGetters(['lang']),
      player_only() {
        return this.$route.query.player_only == 'true'
      },
      var_view() {
        // Check URL parameter and customer default view
        if (this.$route.query.view == 'var') { return true }
        if (this.customerView == 'var') { return true }
        return false
      }
    },
    
    watch: {
     '$route': function() {
        this.setLang()
      },
      '$store.state.customerData': function(newData) {
          try {
            const style = document.createElement('style')
            style.textContent = newData.meta.css
            document.head.append(style)

            // Set customer view data if present in updated customer JSON data
            if (typeof newData.meta.view !== 'undefined') {
              this.customerView = newData.meta.view
            }
          } catch {
            console.log('Error in applying style from json')
          }
      }
    }
  }
</script>

<style lang="scss">
  * {
    box-sizing: border-box;
  }

  body {
    padding: 0;
    margin: 0;
  }

  #app {
    // display: grid;
    grid-template-rows: 50px 1fr;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh !important;
  }
</style>
