<template>
    <div class="tags">
        <!-- <div style="text-align: center;">
            <b-spinner v-if="loading" class="mt-3"></b-spinner>
        </div> -->
        <div v-if="noTags" style="text-align: center" class="mt-2">Ei tageja</div>
        <div @click="openTag(tag)" class="tag" v-for="tag in tags" :key="tag.uuid">
            <div class="time">{{prettyTime(tag.stream_time)}}</div>
            <div class="text">{{tag.meta.bookmark}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['tags'],
    data() {
        return {
            // tags: [],
            refreshInterval: undefined,
            lastChosen: undefined,
            loading: true
        }
    },
    created() {
        // clearInterval(this.refreshInterval)
        // this.getTags()
        // this.refreshInterval = setInterval(() => {
        //     this.getTags()
        // }, 1000 * 60)
    },
    updated() {
    },
    computed: {
        noTags() {
            if(!this.tags) return true;
            return !this.tags.length
        }
    },
    methods: {
        /*getTags() { // Tagit tulee propsien kautta sisään, nouto EventView-komponentissa
            const url = `${this.$store.state.apiUrl}/tags/${this.$route.params.eventid}.json`
            fetch(url)
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    if(data) this.tags = data.tags.sort((a, b) => {return a.stream_time - b.stream_time});
                    this.checkStarttime()
                    this.tags = this.tags.filter(tag => { return tag.meta.type == 42 })
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    this.loading = false;

                })
        },*/
        checkStarttime() {
            this.tags.find(tag => {
                if(tag.meta.type == 46 && !this.$store.getters.starttimeSet) {
                    this.$emit('starttime', tag)
                    this.$store.commit('setStarttime')
                    return ''
                }
            })

            if(!this.$store.getters.starttimeSet) {
                this.$emit('starttime', {stream_time: 0})
                return
            }
        },
        prettyTime(time) {
            let hours = time / 60 / 60;
            hours = Math.floor(hours)
            let minutes = ( time - hours * 60 * 60 ) / 60;
            minutes = Math.floor(minutes)
            let seconds = time - ( hours * 60 * 60 ) - ( minutes * 60 )
            seconds = Math.floor(seconds)
            hours = '0' + hours.toString()
            if(minutes < 10) minutes = '0' + minutes.toString()
            if(seconds < 10) seconds = '0' + seconds.toString()
            return `${hours}:${minutes}:${seconds}`;
        },
        openTag(tag) {
            this.$emit('go-to', tag.stream_time)
            // this.lastChosen = undefined;
            // this.lastChosen = tag.uuid;
        }
    },
    destroyed() {
        if(this.refreshInterval) clearInterval(this.refreshInterval)
    },
    watch: {
        'tags': {
            handler() {
                this.loading = false;
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
    .tags {
        overflow-y: scroll;

        .tag {
            cursor: pointer;
            display: flex;
            margin: 3px 4px;
            flex-direction: row;
            padding: 0 10px 0 5px;
            border-radius: 5px;

            .time {
                // width: 90px;
                margin: 0 7px 0 3px;
                font-size: 20px;
                color:grey;
            }

            .text {
                margin-top: 1px;
                text-align: left;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
</style>
